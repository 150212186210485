import React, { FC, useState } from 'react';
import { Group, City } from '@app/core/models';
import {
  Box,
  Button,
  Dialog,
  DialogProps,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ConfirmationModal } from '../ConfirmationModal';
import {
  useCreateCityMutation,
  useDeleteCityMutation,
  useUpdateCityMutation,
} from '@app/core/store/cities';
import { useGetRegionsListQuery } from '@app/core/store/regions';
import { LocalSelectV2 } from '../LocalSelectV2/LocalSelectV2';

const validationSchema = yup.object().shape({
  city: yup.string().required('Введите название'),
});

interface FormikData {
  city: string;
  region: string | number;
}

interface CityDialogProps extends DialogProps {
  city?: City;
  onSave?: () => void;
}

export const CityDialog: FC<CityDialogProps> = ({
  city,
  onClose,
  onSave,
  ...dialogProps
}) => {
  const [confirm, setConfirm] = useState(false);

  const [updateCity] = useUpdateCityMutation();
  const [createCity] = useCreateCityMutation();
  const [deleteCity] = useDeleteCityMutation();

  const regionsApi = useGetRegionsListQuery({});
  const regions = regionsApi.data?.data.results ?? [];

  const submitApi = async (values: FormikData, id?: Group['id']) => {
    if (id) {
      try {
        await updateCity({ ...values, id }).unwrap();
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    } else {
      try {
        await createCity(values).unwrap();
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    }
  };

  const handleFormikSubmit = async (values: FormikData) => {
    const isSubmitted = await submitApi(values, city?.id);
    if (isSubmitted) {
      onSave?.();
      onClose?.({}, 'backdropClick');
    }
  };

  const { values, handleChange, handleSubmit, dirty, isValid } =
    useFormik<FormikData>({
      validationSchema,
      onSubmit: handleFormikSubmit,
      initialValues: {
        city: city?.city ?? '',
        region: city?.regionId ?? '',
      },
    });

  const handleCancelClick = () => {
    onClose?.({}, 'backdropClick');
  };

  const handleAcceptClick = () => {
    handleSubmit();
  };

  const handleDeleteClick = () => {
    setConfirm(true);
  };

  const handleConfirm = async () => {
    if (!city) return;
    try {
      await deleteCity({ id: city.id }).unwrap();
      onClose?.({}, 'backdropClick');
    } catch (error) {
      console.error(error);
      setConfirm(false);
    }
  };

  return (
    <Dialog
      onClose={onClose}
      PaperProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '20px',
          alignItems: 'flex-start',
          gap: '16px',
        },
      }}
      {...dialogProps}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '0 0 16px 0',
          width: '100%',
        }}
      >
        <Typography sx={{ fontSize: '30px' }}>
          {city ? city.city : 'Новый населенный пункт'}
        </Typography>
        {City ? (
          <Button color="error" onClick={handleDeleteClick}>
            Удалить
          </Button>
        ) : null}
      </Box>
      <TextField
        label="Название *"
        name="city"
        value={values.city}
        onChange={handleChange}
        fullWidth
      />
      <LocalSelectV2
        label="Регион *"
        value={values.region}
        onChange={handleChange}
        inputProps={{ name: 'region' }}
        sx={{ width: '100%' }}
      >
        {regions.map(r => (
          <MenuItem key={r.id} value={r.id}>
            {r.region}
          </MenuItem>
        ))}
      </LocalSelectV2>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '16px 0 0 0',
          width: '100%',
        }}
      >
        <Button variant="contained" onClick={handleCancelClick}>
          {City ? 'Закрыть без изменений' : 'Отмена'}
        </Button>
        <Button
          onClick={handleAcceptClick}
          disabled={City ? !dirty || !isValid : !isValid}
        >
          {City ? 'Сохранить изменения' : 'Создать'}
        </Button>
      </Box>
      <ConfirmationModal
        open={confirm}
        onConfirm={handleConfirm}
        onClose={() => setConfirm(false)}
      />
    </Dialog>
  );
};
