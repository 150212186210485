import { Add } from '@mui/icons-material';
import {
  FormControl,
  MenuItem,
  Checkbox,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { Field } from 'formik';
import React, { memo, useState, useEffect, ReactNode } from 'react';

interface LocalSelectItem {
  id: string | number;
  label: string;
  checked: boolean;
}

interface Props {
  name?: string;
  label?: ReactNode;
  options: Array<LocalSelectItem>;
  onSelect?: (values: string[]) => void;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  addNew?: { label: string; onClick: () => void };
}

export const LocalMultiSelect: React.FC<Props> = memo(
  ({
    name,
    options,
    label,
    onSelect,
    className,
    disabled,
    required,
    addNew,
  }) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
      setSelectedOptions(options.filter(o => o.checked).map(o => String(o.id)));
    }, [options]);

    const handleChange = ({
      target: { value },
    }: SelectChangeEvent<unknown>) => {
      if (Array.isArray(value) && value.includes('0')) {
        setOpen(false);
        addNew?.onClick();
        return;
      }
      setSelectedOptions(
        typeof value === 'string' ? value.split(', ') : (value as string[]),
      );
      onSelect?.(
        typeof value === 'string' ? value.split(', ') : (value as string[]),
      );
    };

    const renderValue = (value: string[]) =>
      value.map(v => options.find(o => String(o.id) === v)?.label).join(', ');

    if (!options?.length) return null;

    return (
      <FormControl fullWidth className={className}>
        <span className="text-xl mb-2.5">{label}</span>
        <Field name={name} value={selectedOptions} disabled={disabled}>
          {() => (
            <Select
              multiple
              value={selectedOptions}
              onChange={handleChange}
              renderValue={renderValue}
              required={required}
              sx={{ borderRadius: '10px' }}
              open={open}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
            >
              {addNew && (
                <MenuItem key={0} value="0">
                  <Add sx={{ margin: '0 10px' }} />
                  {addNew.label}
                </MenuItem>
              )}
              {options.map(option => (
                <MenuItem key={option.id} value={String(option.id)}>
                  <Checkbox checked={option.checked} />
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          )}
        </Field>
      </FormControl>
    );
  },
);
